.items
  margin-top: 28px

.item
  border: 1px solid $grey200
  border-top: 0
  padding: 18px 8px

  &:first-child
    border-top: 1px solid $grey200

.title
  font-size: 14px
  font-weight: bold
  line-height: 1.5

.params
  margin-top: 8px

.param
  margin-top: 14px
  font-size: 14px
  cf()

  &:first-child
    margin-top: 0

.label
  width: 48%
  float: left
  color: $grey700

.value
  width: 52%
  float: left
  font-weight: bold

.address
  font-weight: bold
  margin-top: 15px
  font-size: 14px
.loading
  background: url(../../../styles/img/spin.svg)
  width: 50px
  height: 50px
  margin: 0 auto

.field
  margin-bottom: 14px
  font-size: 14px
  cf()

.label
  width: 50%
  float: left
  color: $grey700

.value
  width: 50%
  float: left
  font-weight: bold
.loading {
  background: url("../../../styles/img/spin.svg");
  width: 50px;
  height: 50px;
  margin: 0 auto;
}
.order_info {
  min-height: 1px;
  float: left;
  width: 540px;
  margin-left: 15px;
  margin-right: 15px;
}
.field {
  font-size: 14px;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 20px;
}
.field:after {
  content: '';
  display: table;
  clear: both;
}
.label {
  min-height: 1px;
  float: left;
  width: 160px;
  margin-left: 15px;
  margin-right: 15px;
  color: #717171;
}
.value {
  min-height: 1px;
  float: left;
  width: 350px;
  margin-left: 15px;
  margin-right: 15px;
  font-weight: bold;
}

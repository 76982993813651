.loading
  background: url(../../../styles/img/spin.svg)
  width: 50px
  height: 50px
  margin: 0 auto

.order_info
  span(6)

.field
  font-size: 14px
  container()
  margin-bottom: 20px

.label
  span(2)
  color: $grey700

.value
  span(4)
  font-weight: bold
